@import '../../constants/variables';

.footer .container{
    text-align: center;
    font-size: 1em;
    letter-spacing: 1.5px;
    padding: 5% ;

    span {
        color: $tangerine-color;
    }
}
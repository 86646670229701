@import 'constants/variables';

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 2px;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;

  body {
    background-color: $oxford-blue-color;
    color: $white-color;
    overflow-x: hidden;
  }
}


@import '../../constants/variables';

.container {
    padding: 2% 10%;
}

a, button {
    cursor: pointer;
    text-decoration: none;
    background: transparent;
}

.button {
    padding: 15px 30px;
    border: solid 2px;
    border-radius: 2px;
    margin: 10px 0;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    transition: .5s;
}

.btn-block {
    width: 100%;
}

.lg {
    max-width: 350px;
    font-size: 1em;
}

.md {
    max-width: 280px;
    font-size: 0.8em;
}

.sm {
    max-width: 190px;
    font-size: 0.6em;
}

.default-btn {
    border-color: $prussian-blue-color;
    color: $prussian-blue-color;
}

.collabo-btn,
.primary-btn {
    border-color: $tangerine-color;
    color: $white-color;
}

.secondary-btn {
    border-color: $selective-yellow-color;
    color: $white-color;
}

.info-btn {
    border-color: $light-blue-color;
    color: $white-color;
}

.service-btn,
.text-btn {
    background-color: transparent;
    border-color: $white-color;
    color: $white-color;
}

.default-btn:hover, 
.primary-btn:hover, 
.collabo-btn:hover, 
.secondary-btn:hover, 
.info-btn:hover, 
.service-btn:hover, 
.text-btn:hover {
    background-color: rgba($color: $prussian-blue-color, $alpha: .5);
    transform: scale(1.1);
}

.section-title {
    font-size: 3.8em;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 2.5px;
    position: relative;
    color: $light-blue-color;
}

.section-title::after {
    position: absolute;
    content: attr(data-title);
    //width: 100%;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -40px);
    font-size: 2em;
    opacity: 0.1;
}

.section-description {
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
    letter-spacing: 2.5px;
    opacity: 0.8;
    margin-top: 35px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .section-title {
        font-size: 2.7em;
        margin-top: 5%;
    }

    .section-title::after {
        font-size: 1.6em;
        transform: translate(-50%, -19px);
    }

    .container {
        padding: 2% 7%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .container {
        padding: 2% 6%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .container {
        padding: 2% 6%;
    }
}
$light-blue-color: #8ECAE6;
$blue-green-color: #219EBC;
$prussian-blue-color: #023047;
$selective-yellow-color: #FFB703;
$tangerine-color: #FB8500;
$white-color: #ffffff;
$oxford-blue-color: #0B132B;
$space-cadet-color: #1C2541;
$mn-blue-color: #3A506B;
$dark-gray-color: #343A40;
$dark-black-gray-color: #212529;
$light-gray-color: #CED4DA;
$initial-header-container-padding: 10px 6%;
$initial-section-container-padding: 2% 6%;
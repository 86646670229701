@import '../../constants/variables';

.contact {
    background-color: $white-color;
    padding: 2%;
    color: $dark-black-gray-color;

    .section-title {
        color: $dark-black-gray-color;
    }

    .contact-content {
        display: flex;
        margin-top: 8%;
        background-color: $blue-green-color;

        .contact-form {
            width: 100%;
            padding: 15px 20px;
            background-color: $light-gray-color;
            text-align: center;

            .form-row {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                min-height: 80px;

                label {
                    text-align: left;
                }

                input,
                select,
                textarea {
                    min-height: 45px;
                    border: none;
                    padding: 10px;
                    color: $dark-black-gray-color;
                    outline: none;
                    border: solid 3px $blue-green-color;

                    // &:focus {
                    //     border: solid 3px $blue-green-color;
                    // }
                }
            }

            button:disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }

            .btn-send {
                display: inline-flex;
                align-items: center;
                justify-content: space-evenly;
                margin-top: 10%;
                background-color: $blue-green-color;
                border: none;
            }

            .spinner {
                width: 35px;
                margin-left: 10px;
            }

            .email-sender-msg {
                display: block;
                width: 100%;
                font-size: .75em;
                font-weight: 600;
                letter-spacing: 1px;
                transition: all .2s;
                margin: 5px auto;
            }

            .msg-success {
                color: $tangerine-color;
            }

            .msg-error {
                color: red;
            }
        }

    }

    .contact-infos {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-align: center;
        width: 100%;
        margin: 8% auto 3%;
        background-color: $tangerine-color;

        .contact-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 200px;
            transition: all .2s;

            i {
                color: $oxford-blue-color;
                font-size: 6em;
                margin-bottom: 15%;
            }

            h5 {
                font-size: 1.2em;
                text-transform: uppercase;
                color: $white-color;
            }
        }
    }

}

@media only screen and (min-width: 768px) {
    .contact .contact-content .contact-form .btn-block {
        width: 35%;
    }
}

@media only screen and (min-width: 992px) {
    .contact {
        .contact-content {
            .contact-illustration {
                width: 50%;
                background: url('../../assets/images/contact-hello-img.jpg'), rgba($blue-green-color, 0.8);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                filter: blur(1.2px);
                background-blend-mode: overlay;
            }
        
            .contact-form {
                width: 50%;

                .btn-send, .btn-block {
                    width: 35%;
                    max-width: 250px;
                }
            }
        }

        .contact-infos {
            .contact-item {
                &:hover {
                    transform: scale(1.1);
    
                    i {
                        color: $white-color;
                    }
    
                    h5 {
                        color: $oxford-blue-color;
                    }
                }    
            }
        }
    }
}
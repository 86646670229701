@import '../../constants/variables';

.back-to-top {
    background-color: $tangerine-color;
    //border-radius: 100%;
    font-size: 18px;
    position: fixed;
    right: 2%;
    bottom: 5%;
    width: 20px;
    height: 15px;
    padding: 1% 1.2%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.2s;
    z-index: 15;
}

.back-to-top-visible {
    visibility: visible;
}

.back-to-top-hidden {
    visibility: hidden;
}

/* Extra small devices (phones, 768px and down) */
@media only screen and (max-width: 768px) {
    .back-to-top {
        width: 40px;
        height: 35px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .back-to-top {
        width: 45px;
        height: 40px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .back-to-top {
        width: 45px;
        height: 40px;
    }
}

@import '../../constants/variables';

.skills {
    background-color: $light-blue-color;
    padding: 2%;

    .section-title {
        color: $dark-black-gray-color;
    }

    .skills-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 12%;

        .skill-item {
            width: 100%;
            height: 8px;
            background-color: $dark-gray-color;
            border: none;
            border-radius: 8px;
            margin: 0 10px 60px;
            position: relative;
            transition: .5s;

            .skill-item-details {
                position: absolute;
                top: -30px;
                text-transform: uppercase;
                color: $white-color;
                display: flex;
                justify-content: space-between;

                span {
                    letter-spacing: 1.2px;
                    font-size: 1.2em;
                    font-weight: 700;
                }
            }

            .progress {
                position: absolute;
                top: 0;
                left: 0;
                border: none;
                border-radius: 8px;
                height: 8px;
                background-color: $selective-yellow-color;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-weight: 700;
            }
        }
    }

    .skills-img-items {
        display: grid;
        grid-template-columns: auto auto auto;
        margin-top: 12%;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;

        .skill-item-img {
            width: 100%;
            max-width: 75px;
            margin: 0 10px 20px;

            img {
                width: 100%;
                margin: 0 auto;
            }

            p {
                width: 100%;
                margin-top: 15px;
                text-align: center;
                font-size: .7em;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .skills {
        .skills-items {
            flex-flow: row wrap;
    
            .skill-item {
                max-width: 250px;

                .skill-item-details {

                    span {
                        font-size: .9em;
                    }
                }
            }

            &::after {
                content: "";
                min-width: 250px;
                max-width: 250px;
                margin: 0 10px 60px;
            }
        }

        .skills-img-items {
            grid-template-columns: auto auto auto auto;

            .skill-item-img {
                max-width: 100px;

                p {
                    font-size: 1em;
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .skills {
        .skills-items {
    
            .skill-item {
    
                .skill-item-details {
                    span {
                        font-size: 1em;
                    }
                }
            }
        }

        .skills-img-items {

            .skill-item-img {
                max-width: 150px;

                p {
                    font-size: 1.3em;
                }
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .skills {
        .skills-items {
    
            .skill-item {
                max-width: 320px;
    
                .skill-item-details {
                    span {
                        font-size: 1em;
                    }
                }
            }

            &::after {
                content: "";
                min-width: 320px;
                max-width: 320px;
                margin: 0 10px 60px;
            }
        }

        .skills-img-items {
            grid-template-columns: auto auto auto auto auto;
            .skill-item-img {
                max-width: 150px;

                p {
                    font-size: 1.3em;
                }
            }
        }
    }
}
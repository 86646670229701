@import '../../constants/variables';


.header {
    width: 100%;
    height: 350px;
    //background: url('../../assets/images/header-background.svg'), $oxford-blue-color;
    background: url('../../assets/images/header-code-bg.jpg'), rgba($oxford-blue-color, 0.9);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-blend-mode: overlay;

    header {
        position: fixed;
        width: 100%;
        min-height: 55px;
        z-index: 2;
        transition: height .4s ease-in-out;
        overflow: hidden;

        .container {
            padding: $initial-header-container-padding;
        }
    }
}

nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    .logo {
        position: absolute;
        top: -20px;
        flex: 1;
        font-family: 'Permanent Marker', cursive;
        color: $tangerine-color;
        text-decoration: none;
        font-size: 2.5em;
        font-weight: 600;
        line-height: 2;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        margin-top: 45px;


        li {
            display: block;
            width: 100%;
            margin: 20px 0;

            a {
                font-size: 16px;
                font-weight: 600;
                color: $white-color;
                line-height: 2;
                letter-spacing: 2px;
                text-decoration: none;
                text-transform: uppercase;
                position: relative;
            }
        }

        transition: all .9s ease-in-out;
    }

    .toggle-menu-btn {
        position: absolute;
        top: 4.5px;
        right: 0;
        background: transparent;
        border: none;
        font-weight: 600;
        color: $white-color;
        transition: all .4s;

        i {
            font-size: 2em;
        }
    }
}

.developer-description {
    padding: 55px 10px;
    height: 350px;
    //background-color: $space-cadet-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;


    .hello {
        font-size: 1.2em;
        font-weight: 700;
        color: $tangerine-color;
        text-transform: uppercase;
        display: none;
    }

    .introducing {
        text-align: center;
        white-space: nowrap;

        .name {
            display: block;
            font-size: 2.5em;
            font-weight: 900;
            color: $tangerine-color;
            margin-bottom: 10px;
        }

        .jobtitle {
            font-size: 1.6em;
            font-weight: 700;
        }
    }

    .actions-btn {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        margin: 0 auto;

        a {
            margin: 10px auto;
        }
    }
}
@media only screen and (min-width: 992px) {
    .header {
        height: 50vh;
    }

    .header header {
        overflow: visible;
        height: 50vh;
        position: fixed;
    }

    .header header .container {
        padding: 2% 10%;
    }

    .developer-description {
        padding: 0;
        height: 35vh;
        transform: translateY(25%);

        .introducing {
            .name {
                font-size: 3em;
            }

            .jobtitle {
                font-size: 2em;
            }
        }
    }

    nav {
        flex-direction: row;

        ul {
            flex-direction: row;
            margin-top: 0;
            margin-left: 50px;
            justify-content: flex-end;

            li {
                display: inline-block;
                margin: 0;
                margin-left: 1.2em;
                width: auto;


                &:hover a::after {
                    width: 100%;
                }

                a {
                    font-size: 1.2em;
                    font-weight: 600;
                    color: $white-color;
                    line-height: 2;
                    letter-spacing: 2px;
                    text-decoration: none;
                    text-transform: uppercase;
                    position: relative;

                    &::after {
                        content: '';
                        width: 0;
                        height: 2px;
                        background-color: $selective-yellow-color;
                        position: absolute;
                        left: 0;
                        bottom: -12px;
                        transition: 0.5s;
                    }
                }
            }
        }
    }

    .toggle-menu-btn {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .header {
        height: 65vh;
    }

    .header header {
        height: 65vh;
    }
    .header header .container {
        padding: 0% 10%;
    }

    nav  {
        align-items: center;
    }

    nav .logo {
        position: relative;
        top: 0;
        font-size: 4em;
    }

    nav ul li a {
        font-size: 1.5em;
    }

    .developer-description {
        height: 50vh;
        transform: translateY(22%);

        .introducing .name {
            font-size: 5em;
        }
        .introducing .jobtitle {
            font-size: 3em;
        }

        .actions-btn .lg {
            max-width: 100%;
            font-size: 2em;
        }
    }
}
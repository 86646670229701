@import '../../constants/variables';

$heigth: 350px;

.collaboration {
    background: url('../../assets/images/collaboration-bg.jpg'),rgba(black, 0.7);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: overlay;
    width: 100%;
    height: $heigth;

    .collaboration-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        min-height: $heigth;
        margin: 0 auto;

        h4 {
            max-width: 500px;
            font-size: 1.5em;
            font-weight: 900;
            line-height: 1.5;
            letter-spacing: 1.8px;
            text-align: center;
            color: $white-color;
        }
    }
}

/* Extra small devices (phones, 768px and down) */
@media only screen and (min-width: 768px) {
    .collaboration-content h4 {
        font-size: 1.2em;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 992px) {
    .collaboration .collaboration-content {
        flex-direction: row;
    }
}
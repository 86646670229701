@import '../../constants/variables';

.services {
    background-color: $space-cadet-color;
    width: 100%;
    padding: 2%;

    .conainer {
        padding: $initial-section-container-padding;
    }

    .services-items {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 8%;

        .service-item{
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: $blue-green-color;
            padding: 5% 15px;
            margin-bottom: 20px;

            img {
                width: 100%;
                max-width: 200px;
                height: 100px;
                margin: 0 auto 30px;
            }

            h3 {
                width: 100%;
                font-size: 1.5em;
                font-weight: 900;
                letter-spacing: 2.5px;
                text-transform: uppercase;
                color: $white-color;
            }

            hr {
                width: 30%;
                margin: 25px auto;
                border: none;
                height: 2px;
                background-color: $selective-yellow-color;
            }

            p {
                width: 100%;
                font-size: 1em;
                font-weight: 600;
                color: black;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .services .services-items .service-item {
        width: 40vw;
        min-height: 350px;

        h3 {
            white-space: break-spaces;
            max-width: 245px;
            margin: 0 auto;
        }
    }
}

@media only screen and (min-width: 992px) {
    .services .services-items .service-item {
        width: 30vw;
        max-width: 260px;
        min-height: 380px;
        transition: .5s;

        h3 {
            max-width: 100%;
        }

        &:hover {
            background-color: $selective-yellow-color;
            transform: translateY(-15px);

            hr{
                background-color: $white-color;
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .services .services-items {
        justify-content: center;
    }
    .services .services-items .service-item {
        max-width: 320px;

        &:nth-child(2) {
            margin-left:10px;
            margin-right:10px;
        }

        h3 {
            font-size: 1.3em;
            height: 50px;
        }
    }
}
